import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  NodeOptionalParams,
  GetTopHeaderOptionalParams,
  GetTopHeaderResponse,
  GetCurrentKeyBlockOptionalParams,
  GetCurrentKeyBlockResponse,
  GetCurrentKeyBlockHashOptionalParams,
  GetCurrentKeyBlockHashResponse,
  GetCurrentKeyBlockHeightOptionalParams,
  GetCurrentKeyBlockHeightResponse,
  GetPendingKeyBlockOptionalParams,
  GetPendingKeyBlockResponse,
  GetKeyBlockByHashOptionalParams,
  GetKeyBlockByHashResponse,
  GetKeyBlockByHeightOptionalParams,
  GetKeyBlockByHeightResponse,
  GetMicroBlockHeaderByHashOptionalParams,
  GetMicroBlockHeaderByHashResponse,
  GetMicroBlockTransactionsByHashOptionalParams,
  GetMicroBlockTransactionsByHashResponse,
  GetMicroBlockTransactionByHashAndIndexOptionalParams,
  GetMicroBlockTransactionByHashAndIndexResponse,
  GetMicroBlockTransactionsCountByHashOptionalParams,
  GetMicroBlockTransactionsCountByHashResponse,
  GetCurrentGenerationOptionalParams,
  GetCurrentGenerationResponse,
  GetGenerationByHashOptionalParams,
  GetGenerationByHashResponse,
  GetGenerationByHeightOptionalParams,
  GetGenerationByHeightResponse,
  GetAccountByPubkeyOptionalParams,
  GetAccountByPubkeyResponse,
  GetAccountByPubkeyAndHeightOptionalParams,
  GetAccountByPubkeyAndHeightResponse,
  GetAccountByPubkeyAndHashOptionalParams,
  GetAccountByPubkeyAndHashResponse,
  GetPendingAccountTransactionsByPubkeyOptionalParams,
  GetPendingAccountTransactionsByPubkeyResponse,
  GetAccountNextNonceOptionalParams,
  GetAccountNextNonceResponse,
  DryRunInput,
  ProtectedDryRunTxsOptionalParams,
  ProtectedDryRunTxsResponse,
  GetTransactionByHashOptionalParams,
  GetTransactionByHashResponse,
  GetTransactionInfoByHashOptionalParams,
  GetTransactionInfoByHashResponse,
  EncodedTx,
  PostTransactionOptionalParams,
  PostTransactionResponse,
  GetContractOptionalParams,
  GetContractResponse,
  GetContractCodeOptionalParams,
  GetContractCodeResponse,
  GetContractPoIOptionalParams,
  GetContractPoIResponse,
  GetOracleByPubkeyOptionalParams,
  GetOracleByPubkeyResponse,
  GetOracleQueriesByPubkeyOptionalParams,
  GetOracleQueriesByPubkeyResponse,
  GetOracleQueryByPubkeyAndQueryIdOptionalParams,
  GetOracleQueryByPubkeyAndQueryIdResponse,
  GetAuctionEntryByNameOptionalParams,
  GetAuctionEntryByNameResponse,
  GetNameEntryByNameOptionalParams,
  GetNameEntryByNameResponse,
  GetChannelByPubkeyOptionalParams,
  GetChannelByPubkeyResponse,
  GetPeerPubkeyOptionalParams,
  GetPeerPubkeyResponse,
  GetSyncStatusOptionalParams,
  GetSyncStatusResponse,
  GetStatusOptionalParams,
  GetStatusResponse,
  GetChainEndsOptionalParams,
  GetChainEndsResponse,
  GetCurrencyOptionalParams,
  GetCurrencyResponse,
  GetRecentGasPricesOptionalParams,
  GetRecentGasPricesResponse,
} from "./models";

export class Node extends coreClient.ServiceClient {
  $host: string;
  intAsString?: boolean;

  /**
   * Initializes a new instance of the Node class.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor($host: string, options?: NodeOptionalParams) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: NodeOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-node/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * Get the top header (either key or micro block)
   * @param options The options parameters.
   */
  getTopHeader(
    options?: GetTopHeaderOptionalParams,
  ): Promise<GetTopHeaderResponse> {
    return this.sendOperationRequest({ options }, getTopHeaderOperationSpec);
  }

  /**
   * Get the current key block
   * @param options The options parameters.
   */
  getCurrentKeyBlock(
    options?: GetCurrentKeyBlockOptionalParams,
  ): Promise<GetCurrentKeyBlockResponse> {
    return this.sendOperationRequest(
      { options },
      getCurrentKeyBlockOperationSpec,
    );
  }

  /**
   * Get the hash of the current key block
   * @param options The options parameters.
   */
  getCurrentKeyBlockHash(
    options?: GetCurrentKeyBlockHashOptionalParams,
  ): Promise<GetCurrentKeyBlockHashResponse> {
    return this.sendOperationRequest(
      { options },
      getCurrentKeyBlockHashOperationSpec,
    );
  }

  /**
   * Get the height of the current key block
   * @param options The options parameters.
   */
  getCurrentKeyBlockHeight(
    options?: GetCurrentKeyBlockHeightOptionalParams,
  ): Promise<GetCurrentKeyBlockHeightResponse> {
    return this.sendOperationRequest(
      { options },
      getCurrentKeyBlockHeightOperationSpec,
    );
  }

  /**
   * Get the pending key block
   * @param options The options parameters.
   */
  getPendingKeyBlock(
    options?: GetPendingKeyBlockOptionalParams,
  ): Promise<GetPendingKeyBlockResponse> {
    return this.sendOperationRequest(
      { options },
      getPendingKeyBlockOperationSpec,
    );
  }

  /**
   * Get a key block by hash
   * @param hash The hash of the block - either a keyblock or a microblock
   * @param options The options parameters.
   */
  getKeyBlockByHash(
    hash: string,
    options?: GetKeyBlockByHashOptionalParams,
  ): Promise<GetKeyBlockByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getKeyBlockByHashOperationSpec,
    );
  }

  /**
   * Get a key block by height
   * @param height The height
   * @param options The options parameters.
   */
  getKeyBlockByHeight(
    height: number,
    options?: GetKeyBlockByHeightOptionalParams,
  ): Promise<GetKeyBlockByHeightResponse> {
    return this.sendOperationRequest(
      { height, options },
      getKeyBlockByHeightOperationSpec,
    );
  }

  /**
   * Get a micro block header by hash
   * @param hash The hash of the block - either a keyblock or a microblock
   * @param options The options parameters.
   */
  getMicroBlockHeaderByHash(
    hash: string,
    options?: GetMicroBlockHeaderByHashOptionalParams,
  ): Promise<GetMicroBlockHeaderByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getMicroBlockHeaderByHashOperationSpec,
    );
  }

  /**
   * Get micro block transactions by hash
   * @param hash The hash of the micro block
   * @param options The options parameters.
   */
  getMicroBlockTransactionsByHash(
    hash: string,
    options?: GetMicroBlockTransactionsByHashOptionalParams,
  ): Promise<GetMicroBlockTransactionsByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getMicroBlockTransactionsByHashOperationSpec,
    );
  }

  /**
   * Get a micro block transaction by hash and index
   * @param hash The hash of the micro block
   * @param index The index of the transaction in a block
   * @param options The options parameters.
   */
  getMicroBlockTransactionByHashAndIndex(
    hash: string,
    index: number,
    options?: GetMicroBlockTransactionByHashAndIndexOptionalParams,
  ): Promise<GetMicroBlockTransactionByHashAndIndexResponse> {
    return this.sendOperationRequest(
      { hash, index, options },
      getMicroBlockTransactionByHashAndIndexOperationSpec,
    );
  }

  /**
   * Get micro block transaction count by hash
   * @param hash The hash of the micro block
   * @param options The options parameters.
   */
  getMicroBlockTransactionsCountByHash(
    hash: string,
    options?: GetMicroBlockTransactionsCountByHashOptionalParams,
  ): Promise<GetMicroBlockTransactionsCountByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getMicroBlockTransactionsCountByHashOperationSpec,
    );
  }

  /**
   * Get the current generation
   * @param options The options parameters.
   */
  getCurrentGeneration(
    options?: GetCurrentGenerationOptionalParams,
  ): Promise<GetCurrentGenerationResponse> {
    return this.sendOperationRequest(
      { options },
      getCurrentGenerationOperationSpec,
    );
  }

  /**
   * Get a generation by hash
   * @param hash The hash of the key block
   * @param options The options parameters.
   */
  getGenerationByHash(
    hash: string,
    options?: GetGenerationByHashOptionalParams,
  ): Promise<GetGenerationByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getGenerationByHashOperationSpec,
    );
  }

  /**
   * Get a generation by height
   * @param height The height
   * @param options The options parameters.
   */
  getGenerationByHeight(
    height: number,
    options?: GetGenerationByHeightOptionalParams,
  ): Promise<GetGenerationByHeightResponse> {
    return this.sendOperationRequest(
      { height, options },
      getGenerationByHeightOperationSpec,
    );
  }

  /**
   * Get an account by public key
   * @param pubkey The public key of the account
   * @param options The options parameters.
   */
  getAccountByPubkey(
    pubkey: string,
    options?: GetAccountByPubkeyOptionalParams,
  ): Promise<GetAccountByPubkeyResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getAccountByPubkeyOperationSpec,
    );
  }

  /**
   * Get an account by public key after the opening key block of the generation at height
   * @param pubkey The public key of the account
   * @param height The height
   * @param options The options parameters.
   */
  getAccountByPubkeyAndHeight(
    pubkey: string,
    height: number,
    options?: GetAccountByPubkeyAndHeightOptionalParams,
  ): Promise<GetAccountByPubkeyAndHeightResponse> {
    return this.sendOperationRequest(
      { pubkey, height, options },
      getAccountByPubkeyAndHeightOperationSpec,
    );
  }

  /**
   * Get an account by public key after the block indicated by hash. Can be either a micro block or a
   * keyblock hash
   * @param pubkey The public key of the account
   * @param hash The hash of the block - either a keyblock or a microblock
   * @param options The options parameters.
   */
  getAccountByPubkeyAndHash(
    pubkey: string,
    hash: string,
    options?: GetAccountByPubkeyAndHashOptionalParams,
  ): Promise<GetAccountByPubkeyAndHashResponse> {
    return this.sendOperationRequest(
      { pubkey, hash, options },
      getAccountByPubkeyAndHashOperationSpec,
    );
  }

  /**
   * Get pending account transactions by public key
   * @param pubkey The public key of the account
   * @param options The options parameters.
   */
  getPendingAccountTransactionsByPubkey(
    pubkey: string,
    options?: GetPendingAccountTransactionsByPubkeyOptionalParams,
  ): Promise<GetPendingAccountTransactionsByPubkeyResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getPendingAccountTransactionsByPubkeyOperationSpec,
    );
  }

  /**
   * Get an account's next nonce; This is computed according to whatever is the current account nonce and
   * what transactions are currently present in the transaction pool
   * @param pubkey The public key of the account
   * @param options The options parameters.
   */
  getAccountNextNonce(
    pubkey: string,
    options?: GetAccountNextNonceOptionalParams,
  ): Promise<GetAccountNextNonceResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getAccountNextNonceOperationSpec,
    );
  }

  /**
   * Dry-run unsigned transactions on top of a given block. Supports all TXs except GAMetaTx, PayingForTx
   * and OffchainTx. The maximum gas limit of all calls is capped. The maximum gas limit per request is a
   * global node setting. Since DryRunCallReq object do not have a mandatory gas field, if not set a
   * default value of 1000000 is being used instead.
   * @param body transactions
   * @param options The options parameters.
   */
  protectedDryRunTxs(
    body: DryRunInput,
    options?: ProtectedDryRunTxsOptionalParams,
  ): Promise<ProtectedDryRunTxsResponse> {
    return this.sendOperationRequest(
      { body, options },
      protectedDryRunTxsOperationSpec,
    );
  }

  /**
   * Get a transaction by hash
   * @param hash The hash of the transaction
   * @param options The options parameters.
   */
  getTransactionByHash(
    hash: string,
    options?: GetTransactionByHashOptionalParams,
  ): Promise<GetTransactionByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getTransactionByHashOperationSpec,
    );
  }

  /**
   * @param hash The hash of the transaction
   * @param options The options parameters.
   */
  getTransactionInfoByHash(
    hash: string,
    options?: GetTransactionInfoByHashOptionalParams,
  ): Promise<GetTransactionInfoByHashResponse> {
    return this.sendOperationRequest(
      { hash, options },
      getTransactionInfoByHashOperationSpec,
    );
  }

  /**
   * Post a new transaction
   * @param body The new transaction
   * @param options The options parameters.
   */
  postTransaction(
    body: EncodedTx,
    options?: PostTransactionOptionalParams,
  ): Promise<PostTransactionResponse> {
    return this.sendOperationRequest(
      { body, options },
      postTransactionOperationSpec,
    );
  }

  /**
   * Get a contract by pubkey
   * @param pubkey Contract pubkey to get proof for
   * @param options The options parameters.
   */
  getContract(
    pubkey: string,
    options?: GetContractOptionalParams,
  ): Promise<GetContractResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getContractOperationSpec,
    );
  }

  /**
   * Get contract code by pubkey
   * @param pubkey Contract pubkey to get proof for
   * @param options The options parameters.
   */
  getContractCode(
    pubkey: string,
    options?: GetContractCodeOptionalParams,
  ): Promise<GetContractCodeResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getContractCodeOperationSpec,
    );
  }

  /**
   * Get a proof of inclusion for a contract
   * @param pubkey Contract pubkey to get proof for
   * @param options The options parameters.
   */
  getContractPoI(
    pubkey: string,
    options?: GetContractPoIOptionalParams,
  ): Promise<GetContractPoIResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getContractPoIOperationSpec,
    );
  }

  /**
   * Get an oracle by public key
   * @param pubkey The public key of the oracle
   * @param options The options parameters.
   */
  getOracleByPubkey(
    pubkey: string,
    options?: GetOracleByPubkeyOptionalParams,
  ): Promise<GetOracleByPubkeyResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getOracleByPubkeyOperationSpec,
    );
  }

  /**
   * Get oracle queries by public key
   * @param pubkey The public key of the oracle
   * @param options The options parameters.
   */
  getOracleQueriesByPubkey(
    pubkey: string,
    options?: GetOracleQueriesByPubkeyOptionalParams,
  ): Promise<GetOracleQueriesByPubkeyResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getOracleQueriesByPubkeyOperationSpec,
    );
  }

  /**
   * Get an oracle query by public key and query ID
   * @param pubkey The public key of the oracle
   * @param queryId The ID of the query
   * @param options The options parameters.
   */
  getOracleQueryByPubkeyAndQueryId(
    pubkey: string,
    queryId: string,
    options?: GetOracleQueryByPubkeyAndQueryIdOptionalParams,
  ): Promise<GetOracleQueryByPubkeyAndQueryIdResponse> {
    return this.sendOperationRequest(
      { pubkey, queryId, options },
      getOracleQueryByPubkeyAndQueryIdOperationSpec,
    );
  }

  /**
   * Get auction entry from naming system
   * @param name The name key of the name entry
   * @param options The options parameters.
   */
  getAuctionEntryByName(
    name: string,
    options?: GetAuctionEntryByNameOptionalParams,
  ): Promise<GetAuctionEntryByNameResponse> {
    return this.sendOperationRequest(
      { name, options },
      getAuctionEntryByNameOperationSpec,
    );
  }

  /**
   * Get name entry from naming system
   * @param name The name key of the name entry
   * @param options The options parameters.
   */
  getNameEntryByName(
    name: string,
    options?: GetNameEntryByNameOptionalParams,
  ): Promise<GetNameEntryByNameResponse> {
    return this.sendOperationRequest(
      { name, options },
      getNameEntryByNameOperationSpec,
    );
  }

  /**
   * Get channel by public key
   * @param pubkey The pubkey of the channel
   * @param options The options parameters.
   */
  getChannelByPubkey(
    pubkey: string,
    options?: GetChannelByPubkeyOptionalParams,
  ): Promise<GetChannelByPubkeyResponse> {
    return this.sendOperationRequest(
      { pubkey, options },
      getChannelByPubkeyOperationSpec,
    );
  }

  /**
   * Get peer public key
   * @param options The options parameters.
   */
  getPeerPubkey(
    options?: GetPeerPubkeyOptionalParams,
  ): Promise<GetPeerPubkeyResponse> {
    return this.sendOperationRequest({ options }, getPeerPubkeyOperationSpec);
  }

  /**
   * Get the sync status of a node
   * @param options The options parameters.
   */
  getSyncStatus(
    options?: GetSyncStatusOptionalParams,
  ): Promise<GetSyncStatusResponse> {
    return this.sendOperationRequest({ options }, getSyncStatusOperationSpec);
  }

  /**
   * Get the status of a node
   * @param options The options parameters.
   */
  getStatus(options?: GetStatusOptionalParams): Promise<GetStatusResponse> {
    return this.sendOperationRequest({ options }, getStatusOperationSpec);
  }

  /**
   * Get oldest keyblock hashes counting from genesis including orphans
   * @param options The options parameters.
   */
  getChainEnds(
    options?: GetChainEndsOptionalParams,
  ): Promise<GetChainEndsResponse> {
    return this.sendOperationRequest({ options }, getChainEndsOperationSpec);
  }

  /**
   * Get the currency metadata of a node
   * @param options The options parameters.
   */
  getCurrency(
    options?: GetCurrencyOptionalParams,
  ): Promise<GetCurrencyResponse> {
    return this.sendOperationRequest({ options }, getCurrencyOperationSpec);
  }

  /**
   * Get minimum gas prices in recent blocks
   * @param options The options parameters.
   */
  getRecentGasPrices(
    options?: GetRecentGasPricesOptionalParams,
  ): Promise<GetRecentGasPricesResponse> {
    return this.sendOperationRequest(
      { options },
      getRecentGasPricesOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const getTopHeaderOperationSpec: coreClient.OperationSpec = {
  path: "/v3/headers/top",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Header,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCurrentKeyBlockOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/current",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.KeyBlock,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCurrentKeyBlockHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/current/hash",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.HashResponse,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCurrentKeyBlockHeightOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/current/height",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.HeightResponse,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPendingKeyBlockOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/pending",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.KeyBlock,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getKeyBlockByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/hash/{hash}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.KeyBlock,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const getKeyBlockByHeightOperationSpec: coreClient.OperationSpec = {
  path: "/v3/key-blocks/height/{height}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.KeyBlock,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.height],
  headerParameters: [Parameters.accept],
  serializer,
};
const getMicroBlockHeaderByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/micro-blocks/hash/{hash}/header",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.MicroBlockHeader,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const getMicroBlockTransactionsByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/micro-blocks/hash/{hash}/transactions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SignedTxs,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const getMicroBlockTransactionByHashAndIndexOperationSpec: coreClient.OperationSpec =
  {
    path: "/v3/micro-blocks/hash/{hash}/transactions/index/{index}",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.SignedTx,
      },
      400: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
      404: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
    },
    queryParameters: [Parameters.intAsString],
    urlParameters: [Parameters.$host, Parameters.hash, Parameters.index],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getMicroBlockTransactionsCountByHashOperationSpec: coreClient.OperationSpec =
  {
    path: "/v3/micro-blocks/hash/{hash}/transactions/count",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.CountResponse,
      },
      400: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
      404: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
    },
    queryParameters: [Parameters.intAsString],
    urlParameters: [Parameters.$host, Parameters.hash],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getCurrentGenerationOperationSpec: coreClient.OperationSpec = {
  path: "/v3/generations/current",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Generation,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getGenerationByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/generations/hash/{hash}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Generation,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const getGenerationByHeightOperationSpec: coreClient.OperationSpec = {
  path: "/v3/generations/height/{height}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Generation,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.height],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAccountByPubkeyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/accounts/{pubkey}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Account,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAccountByPubkeyAndHeightOperationSpec: coreClient.OperationSpec = {
  path: "/v3/accounts/{pubkey}/height/{height}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Account,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    410: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.height, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getAccountByPubkeyAndHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/accounts/{pubkey}/hash/{hash}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Account,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPendingAccountTransactionsByPubkeyOperationSpec: coreClient.OperationSpec =
  {
    path: "/v3/accounts/{pubkey}/transactions/pending",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.SignedTxs,
      },
      400: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
      404: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
    },
    queryParameters: [Parameters.intAsString],
    urlParameters: [Parameters.$host, Parameters.pubkey],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getAccountNextNonceOperationSpec: coreClient.OperationSpec = {
  path: "/v3/accounts/{pubkey}/next-nonce",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.NextNonceResponse,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString, Parameters.strategy],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const protectedDryRunTxsOperationSpec: coreClient.OperationSpec = {
  path: "/v3/dry-run",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DryRunResults,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    403: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  requestBody: Parameters.body,
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getTransactionByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/transactions/{hash}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SignedTx,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const getTransactionInfoByHashOperationSpec: coreClient.OperationSpec = {
  path: "/v3/transactions/{hash}/info",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TxInfoObject,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    410: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.hash],
  headerParameters: [Parameters.accept],
  serializer,
};
const postTransactionOperationSpec: coreClient.OperationSpec = {
  path: "/v3/transactions",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.PostTxResponse,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  requestBody: Parameters.body1,
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const getContractOperationSpec: coreClient.OperationSpec = {
  path: "/v3/contracts/{pubkey}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ContractObject,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getContractCodeOperationSpec: coreClient.OperationSpec = {
  path: "/v3/contracts/{pubkey}/code",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ByteCode,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getContractPoIOperationSpec: coreClient.OperationSpec = {
  path: "/v3/contracts/{pubkey}/poi",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PoI,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getOracleByPubkeyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/oracles/{pubkey}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.RegisteredOracle,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getOracleQueriesByPubkeyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/oracles/{pubkey}/queries",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.OracleQueries,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [
    Parameters.intAsString,
    Parameters.fromParam,
    Parameters.limit,
    Parameters.typeParam,
  ],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getOracleQueryByPubkeyAndQueryIdOperationSpec: coreClient.OperationSpec =
  {
    path: "/v3/oracles/{pubkey}/queries/{query-id}",
    httpMethod: "GET",
    responses: {
      200: {
        bodyMapper: Mappers.OracleQuery,
      },
      400: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
      404: {
        bodyMapper: Mappers.ErrorModel,
        isError: true,
      },
    },
    queryParameters: [Parameters.intAsString],
    urlParameters: [Parameters.$host, Parameters.pubkey, Parameters.queryId],
    headerParameters: [Parameters.accept],
    serializer,
  };
const getAuctionEntryByNameOperationSpec: coreClient.OperationSpec = {
  path: "/v3/auctions/{name}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.AuctionEntry,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.name],
  headerParameters: [Parameters.accept],
  serializer,
};
const getNameEntryByNameOperationSpec: coreClient.OperationSpec = {
  path: "/v3/names/{name}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.NameEntry,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.name],
  headerParameters: [Parameters.accept],
  serializer,
};
const getChannelByPubkeyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/channels/{pubkey}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Channel,
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host, Parameters.pubkey],
  headerParameters: [Parameters.accept],
  serializer,
};
const getPeerPubkeyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/peers/pubkey",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.PeerPubKey,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getSyncStatusOperationSpec: coreClient.OperationSpec = {
  path: "/v3/sync-status",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SyncStatus,
    },
    404: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getStatusOperationSpec: coreClient.OperationSpec = {
  path: "/v3/status",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Status,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getChainEndsOperationSpec: coreClient.OperationSpec = {
  path: "/v3/status/chain-ends",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Sequence", element: { type: { name: "String" } } },
      },
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getCurrencyOperationSpec: coreClient.OperationSpec = {
  path: "/v3/currency",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Currency,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
const getRecentGasPricesOperationSpec: coreClient.OperationSpec = {
  path: "/v3/recent-gas-prices",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "GasPricesItem" } },
        },
      },
    },
    400: {
      bodyMapper: Mappers.ErrorModel,
      isError: true,
    },
  },
  queryParameters: [Parameters.intAsString],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer,
};
