import * as coreClient from "@azure/core-client";

export const Header: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Header",
    modelProperties: {
      hash: {
        serializedName: "hash",
        required: true,
        type: {
          name: "String",
        },
      },
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
      prevHash: {
        serializedName: "prev_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      prevKeyHash: {
        serializedName: "prev_key_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      miner: {
        serializedName: "miner",
        type: {
          name: "String",
        },
      },
      beneficiary: {
        serializedName: "beneficiary",
        type: {
          name: "String",
        },
      },
      target: {
        serializedName: "target",
        type: {
          name: "String",
        },
      },
      pow: {
        constraints: {
          MinItems: 42,
          MaxItems: 42,
        },
        serializedName: "pow",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      time: {
        serializedName: "time",
        required: true,
        type: {
          name: "String",
        },
      },
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
      info: {
        serializedName: "info",
        type: {
          name: "String",
        },
      },
      pofHash: {
        serializedName: "pof_hash",
        type: {
          name: "String",
        },
      },
      txsHash: {
        serializedName: "txs_hash",
        type: {
          name: "String",
        },
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ErrorModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorModel",
    modelProperties: {
      reason: {
        serializedName: "reason",
        required: true,
        type: {
          name: "String",
        },
      },
      errorCode: {
        serializedName: "error_code",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const KeyBlock: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "KeyBlock",
    modelProperties: {
      hash: {
        serializedName: "hash",
        required: true,
        type: {
          name: "String",
        },
      },
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
      prevHash: {
        serializedName: "prev_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      prevKeyHash: {
        serializedName: "prev_key_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      miner: {
        serializedName: "miner",
        required: true,
        type: {
          name: "String",
        },
      },
      beneficiary: {
        serializedName: "beneficiary",
        required: true,
        type: {
          name: "String",
        },
      },
      target: {
        serializedName: "target",
        required: true,
        type: {
          name: "String",
        },
      },
      pow: {
        constraints: {
          MinItems: 42,
          MaxItems: 42,
        },
        serializedName: "pow",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      time: {
        serializedName: "time",
        required: true,
        type: {
          name: "String",
        },
      },
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
      info: {
        serializedName: "info",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const HashResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "HashResponse",
    modelProperties: {
      hash: {
        serializedName: "hash",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const HeightResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "HeightResponse",
    modelProperties: {
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const MicroBlockHeader: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MicroBlockHeader",
    modelProperties: {
      hash: {
        serializedName: "hash",
        required: true,
        type: {
          name: "String",
        },
      },
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
      pofHash: {
        serializedName: "pof_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      prevHash: {
        serializedName: "prev_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      prevKeyHash: {
        serializedName: "prev_key_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      txsHash: {
        serializedName: "txs_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      signature: {
        serializedName: "signature",
        required: true,
        type: {
          name: "String",
        },
      },
      time: {
        serializedName: "time",
        required: true,
        type: {
          name: "String",
        },
      },
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SignedTxs: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SignedTxs",
    modelProperties: {
      transactions: {
        serializedName: "transactions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SignedTx",
            },
          },
        },
      },
    },
  },
};

export const SignedTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SignedTx",
    modelProperties: {
      tx: {
        serializedName: "tx",
        type: {
          name: "Composite",
          className: "Tx",
        },
      },
      blockHeight: {
        serializedName: "block_height",
        required: true,
        type: {
          name: "String",
        },
      },
      blockHash: {
        serializedName: "block_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      hash: {
        serializedName: "hash",
        required: true,
        type: {
          name: "String",
        },
      },
      encodedTx: {
        serializedName: "encoded_tx",
        type: {
          name: "String",
        },
      },
      signatures: {
        serializedName: "signatures",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const Tx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Tx",
    modelProperties: {
      recipientId: {
        serializedName: "recipient_id",
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      senderId: {
        serializedName: "sender_id",
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        type: {
          name: "String",
        },
      },
      initiatorId: {
        serializedName: "initiator_id",
        type: {
          name: "String",
        },
      },
      initiatorAmount: {
        serializedName: "initiator_amount",
        type: {
          name: "String",
        },
      },
      responderId: {
        serializedName: "responder_id",
        type: {
          name: "String",
        },
      },
      responderAmount: {
        serializedName: "responder_amount",
        type: {
          name: "String",
        },
      },
      channelReserve: {
        serializedName: "channel_reserve",
        type: {
          name: "String",
        },
      },
      lockPeriod: {
        serializedName: "lock_period",
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        type: {
          name: "String",
        },
      },
      delegateIds: {
        serializedName: "delegate_ids",
        type: {
          name: "Composite",
          className: "Delegates",
        },
      },
      channelId: {
        serializedName: "channel_id",
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        type: {
          name: "String",
        },
      },
      toId: {
        serializedName: "to_id",
        type: {
          name: "String",
        },
      },
      update: {
        serializedName: "update",
        type: {
          name: "Composite",
          className: "OffChainUpdate",
        },
      },
      offchainTrees: {
        serializedName: "offchain_trees",
        type: {
          name: "String",
        },
      },
      initiatorAmountFinal: {
        serializedName: "initiator_amount_final",
        type: {
          name: "String",
        },
      },
      responderAmountFinal: {
        serializedName: "responder_amount_final",
        type: {
          name: "String",
        },
      },
      poi: {
        serializedName: "poi",
        type: {
          name: "String",
        },
      },
      initiatorDelegateIds: {
        serializedName: "initiator_delegate_ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      responderDelegateIds: {
        serializedName: "responder_delegate_ids",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      queryFormat: {
        serializedName: "query_format",
        type: {
          name: "String",
        },
      },
      responseFormat: {
        serializedName: "response_format",
        type: {
          name: "String",
        },
      },
      queryFee: {
        serializedName: "query_fee",
        type: {
          name: "String",
        },
      },
      oracleTtl: {
        serializedName: "oracle_ttl",
        type: {
          name: "Composite",
          className: "RelativeTTL",
        },
      },
      accountId: {
        serializedName: "account_id",
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        type: {
          name: "String",
        },
      },
      oracleId: {
        serializedName: "oracle_id",
        type: {
          name: "String",
        },
      },
      query: {
        serializedName: "query",
        type: {
          name: "String",
        },
      },
      queryTtl: {
        serializedName: "query_ttl",
        type: {
          name: "Composite",
          className: "Ttl",
        },
      },
      responseTtl: {
        serializedName: "response_ttl",
        type: {
          name: "Composite",
          className: "RelativeTTL",
        },
      },
      queryId: {
        serializedName: "query_id",
        type: {
          name: "String",
        },
      },
      response: {
        serializedName: "response",
        type: {
          name: "String",
        },
      },
      commitmentId: {
        serializedName: "commitment_id",
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      nameSalt: {
        serializedName: "name_salt",
        type: {
          name: "String",
        },
      },
      nameFee: {
        serializedName: "name_fee",
        type: {
          name: "String",
        },
      },
      nameId: {
        serializedName: "name_id",
        type: {
          name: "String",
        },
      },
      nameTtl: {
        serializedName: "name_ttl",
        type: {
          name: "String",
        },
      },
      pointers: {
        serializedName: "pointers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NamePointer",
            },
          },
        },
      },
      clientTtl: {
        serializedName: "client_ttl",
        type: {
          name: "String",
        },
      },
      ownerId: {
        serializedName: "owner_id",
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        type: {
          name: "String",
        },
      },
      vmVersion: {
        serializedName: "vm_version",
        type: {
          name: "String",
        },
      },
      deposit: {
        serializedName: "deposit",
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        type: {
          name: "String",
        },
      },
      callerId: {
        serializedName: "caller_id",
        type: {
          name: "String",
        },
      },
      contractId: {
        serializedName: "contract_id",
        type: {
          name: "String",
        },
      },
      authFun: {
        constraints: {
          Pattern: new RegExp("^(0x|0X)?[a-fA-F0-9]+$"),
        },
        serializedName: "auth_fun",
        type: {
          name: "String",
        },
      },
      gaId: {
        serializedName: "ga_id",
        type: {
          name: "String",
        },
      },
      authData: {
        serializedName: "auth_data",
        type: {
          name: "String",
        },
      },
      tx: {
        serializedName: "tx",
        type: {
          name: "Composite",
          className: "SignedTx",
        },
      },
      payerId: {
        serializedName: "payer_id",
        type: {
          name: "String",
        },
      },
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "SpendTx",
            "ChannelCreateTx",
            "ChannelDepositTx",
            "ChannelWithdrawTx",
            "ChannelForceProgressTx",
            "ChannelCloseMutualTx",
            "ChannelCloseSoloTx",
            "ChannelSlashTx",
            "ChannelSettleTx",
            "ChannelSnapshotSoloTx",
            "ChannelSetDelegatesTx",
            "OracleRegisterTx",
            "OracleExtendTx",
            "OracleQueryTx",
            "OracleRespondTx",
            "NamePreclaimTx",
            "NameClaimTx",
            "NameUpdateTx",
            "NameTransferTx",
            "NameRevokeTx",
            "ContractCreateTx",
            "ContractCallTx",
            "GAAttachTx",
            "GAMetaTx",
            "PayingForTx",
          ],
        },
      },
    },
  },
};

export const Delegates: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Delegates",
    modelProperties: {
      initiator: {
        serializedName: "initiator",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      responder: {
        serializedName: "responder",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const OffChainUpdate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OffChainUpdate",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: {
      serializedName: "op",
      clientName: "op",
    },
    modelProperties: {
      op: {
        serializedName: "op",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const RelativeTTL: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RelativeTTL",
    modelProperties: {
      type: {
        defaultValue: "delta",
        isConstant: true,
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Ttl: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Ttl",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["delta", "block"],
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NamePointer: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NamePointer",
    modelProperties: {
      key: {
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CountResponse",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Generation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Generation",
    modelProperties: {
      keyBlock: {
        serializedName: "key_block",
        type: {
          name: "Composite",
          className: "KeyBlock",
        },
      },
      microBlocks: {
        serializedName: "micro_blocks",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const Account: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Account",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      balance: {
        serializedName: "balance",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        required: true,
        type: {
          name: "String",
        },
      },
      payable: {
        serializedName: "payable",
        type: {
          name: "Boolean",
        },
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "Enum",
          allowedValues: ["basic", "generalized"],
        },
      },
      contractId: {
        serializedName: "contract_id",
        type: {
          name: "String",
        },
      },
      authFun: {
        serializedName: "auth_fun",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NextNonceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NextNonceResponse",
    modelProperties: {
      nextNonce: {
        serializedName: "next_nonce",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DryRunInput: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunInput",
    modelProperties: {
      top: {
        serializedName: "top",
        type: {
          name: "String",
        },
      },
      accounts: {
        serializedName: "accounts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DryRunAccount",
            },
          },
        },
      },
      txs: {
        serializedName: "txs",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DryRunInputItem",
            },
          },
        },
      },
      txEvents: {
        defaultValue: false,
        serializedName: "tx_events",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const DryRunAccount: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunAccount",
    modelProperties: {
      pubKey: {
        serializedName: "pub_key",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DryRunInputItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunInputItem",
    modelProperties: {
      tx: {
        serializedName: "tx",
        type: {
          name: "String",
        },
      },
      txHash: {
        serializedName: "tx_hash",
        type: {
          name: "String",
        },
      },
      callReq: {
        serializedName: "call_req",
        type: {
          name: "Composite",
          className: "DryRunCallReq",
        },
      },
    },
  },
};

export const DryRunCallReq: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunCallReq",
    modelProperties: {
      calldata: {
        serializedName: "calldata",
        required: true,
        type: {
          name: "String",
        },
      },
      contract: {
        serializedName: "contract",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        type: {
          name: "String",
        },
      },
      caller: {
        serializedName: "caller",
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        type: {
          name: "String",
        },
      },
      context: {
        serializedName: "context",
        type: {
          name: "Composite",
          className: "DryRunCallContext",
        },
      },
    },
  },
};

export const DryRunCallContext: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunCallContext",
    modelProperties: {
      tx: {
        serializedName: "tx",
        type: {
          name: "String",
        },
      },
      txHash: {
        serializedName: "tx_hash",
        type: {
          name: "String",
        },
      },
      stateful: {
        serializedName: "stateful",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const DryRunResults: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunResults",
    modelProperties: {
      results: {
        serializedName: "results",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DryRunResult",
            },
          },
        },
      },
      txEvents: {
        serializedName: "tx_events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Dictionary",
              value: { type: { name: "any" } },
            },
          },
        },
      },
    },
  },
};

export const DryRunResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DryRunResult",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      result: {
        serializedName: "result",
        required: true,
        type: {
          name: "String",
        },
      },
      reason: {
        serializedName: "reason",
        type: {
          name: "String",
        },
      },
      callObj: {
        serializedName: "call_obj",
        type: {
          name: "Composite",
          className: "ContractCallObject",
        },
      },
    },
  },
};

export const ContractCallObject: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContractCallObject",
    modelProperties: {
      callerId: {
        serializedName: "caller_id",
        required: true,
        type: {
          name: "String",
        },
      },
      callerNonce: {
        serializedName: "caller_nonce",
        required: true,
        type: {
          name: "String",
        },
      },
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
      contractId: {
        serializedName: "contract_id",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      gasUsed: {
        serializedName: "gas_used",
        required: true,
        type: {
          name: "String",
        },
      },
      log: {
        serializedName: "log",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Event",
            },
          },
        },
      },
      returnValue: {
        serializedName: "return_value",
        required: true,
        type: {
          name: "String",
        },
      },
      returnType: {
        serializedName: "return_type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["ok", "error", "revert"],
        },
      },
    },
  },
};

export const Event: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Event",
    modelProperties: {
      address: {
        serializedName: "address",
        required: true,
        type: {
          name: "String",
        },
      },
      topics: {
        serializedName: "topics",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TxInfoObject: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TxInfoObject",
    modelProperties: {
      callInfo: {
        serializedName: "call_info",
        type: {
          name: "Composite",
          className: "ContractCallObject",
        },
      },
      gaInfo: {
        serializedName: "ga_info",
        type: {
          name: "Composite",
          className: "GAObject",
        },
      },
      txInfo: {
        serializedName: "tx_info",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GAObject: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GAObject",
    modelProperties: {
      callerId: {
        serializedName: "caller_id",
        required: true,
        type: {
          name: "String",
        },
      },
      height: {
        serializedName: "height",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      gasUsed: {
        serializedName: "gas_used",
        required: true,
        type: {
          name: "String",
        },
      },
      returnValue: {
        serializedName: "return_value",
        required: true,
        type: {
          name: "String",
        },
      },
      returnType: {
        serializedName: "return_type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["ok", "error"],
        },
      },
      innerObject: {
        serializedName: "inner_object",
        type: {
          name: "Composite",
          className: "TxInfoObject",
        },
      },
    },
  },
};

export const EncodedTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EncodedTx",
    modelProperties: {
      tx: {
        serializedName: "tx",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PostTxResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PostTxResponse",
    modelProperties: {
      txHash: {
        serializedName: "tx_hash",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ContractObject: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContractObject",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      ownerId: {
        serializedName: "owner_id",
        required: true,
        type: {
          name: "String",
        },
      },
      vmVersion: {
        serializedName: "vm_version",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      active: {
        serializedName: "active",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      referrerIds: {
        serializedName: "referrer_ids",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      deposit: {
        serializedName: "deposit",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ByteCode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ByteCode",
    modelProperties: {
      bytecode: {
        serializedName: "bytecode",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PoI: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PoI",
    modelProperties: {
      poi: {
        serializedName: "poi",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const RegisteredOracle: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RegisteredOracle",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      queryFormat: {
        serializedName: "query_format",
        required: true,
        type: {
          name: "String",
        },
      },
      responseFormat: {
        serializedName: "response_format",
        required: true,
        type: {
          name: "String",
        },
      },
      queryFee: {
        serializedName: "query_fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OracleQueries: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleQueries",
    modelProperties: {
      oracleQueries: {
        serializedName: "oracle_queries",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OracleQuery",
            },
          },
        },
      },
    },
  },
};

export const OracleQuery: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleQuery",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      senderId: {
        serializedName: "sender_id",
        required: true,
        type: {
          name: "String",
        },
      },
      senderNonce: {
        serializedName: "sender_nonce",
        required: true,
        type: {
          name: "String",
        },
      },
      oracleId: {
        serializedName: "oracle_id",
        required: true,
        type: {
          name: "String",
        },
      },
      query: {
        serializedName: "query",
        required: true,
        type: {
          name: "String",
        },
      },
      response: {
        serializedName: "response",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        required: true,
        type: {
          name: "String",
        },
      },
      responseTtl: {
        serializedName: "response_ttl",
        type: {
          name: "Composite",
          className: "Ttl",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AuctionEntry: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuctionEntry",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      startedAt: {
        serializedName: "started_at",
        type: {
          name: "String",
        },
      },
      endsAt: {
        serializedName: "ends_at",
        required: true,
        type: {
          name: "String",
        },
      },
      highestBidder: {
        serializedName: "highest_bidder",
        required: true,
        type: {
          name: "String",
        },
      },
      highestBid: {
        serializedName: "highest_bid",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameEntry: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameEntry",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      owner: {
        serializedName: "owner",
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        required: true,
        type: {
          name: "String",
        },
      },
      pointers: {
        serializedName: "pointers",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NamePointer",
            },
          },
        },
      },
    },
  },
};

export const Channel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Channel",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorId: {
        serializedName: "initiator_id",
        required: true,
        type: {
          name: "String",
        },
      },
      responderId: {
        serializedName: "responder_id",
        required: true,
        type: {
          name: "String",
        },
      },
      channelAmount: {
        serializedName: "channel_amount",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorAmount: {
        serializedName: "initiator_amount",
        required: true,
        type: {
          name: "String",
        },
      },
      responderAmount: {
        serializedName: "responder_amount",
        required: true,
        type: {
          name: "String",
        },
      },
      channelReserve: {
        serializedName: "channel_reserve",
        required: true,
        type: {
          name: "String",
        },
      },
      delegateIds: {
        serializedName: "delegate_ids",
        type: {
          name: "Composite",
          className: "Delegates",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        required: true,
        type: {
          name: "String",
        },
      },
      soloRound: {
        serializedName: "solo_round",
        required: true,
        type: {
          name: "String",
        },
      },
      lockPeriod: {
        serializedName: "lock_period",
        required: true,
        type: {
          name: "String",
        },
      },
      lockedUntil: {
        serializedName: "locked_until",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PeerPubKey: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerPubKey",
    modelProperties: {
      pubkey: {
        serializedName: "pubkey",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SyncStatus: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SyncStatus",
    modelProperties: {
      progress: {
        serializedName: "progress",
        required: true,
        type: {
          name: "Number",
        },
      },
      target: {
        serializedName: "target",
        required: true,
        type: {
          name: "String",
        },
      },
      speed: {
        serializedName: "speed",
        required: true,
        type: {
          name: "Number",
        },
      },
      estimate: {
        serializedName: "estimate",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Status: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Status",
    modelProperties: {
      genesisKeyBlockHash: {
        serializedName: "genesis_key_block_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      solutions: {
        serializedName: "solutions",
        required: true,
        type: {
          name: "String",
        },
      },
      difficulty: {
        serializedName: "difficulty",
        required: true,
        type: {
          name: "String",
        },
      },
      hashrate: {
        serializedName: "hashrate",
        required: true,
        type: {
          name: "String",
        },
      },
      syncing: {
        serializedName: "syncing",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      syncProgress: {
        constraints: {
          InclusiveMaximum: 100,
          InclusiveMinimum: 0,
        },
        serializedName: "sync_progress",
        type: {
          name: "Number",
        },
      },
      uptime: {
        serializedName: "uptime",
        required: true,
        type: {
          name: "String",
        },
      },
      listening: {
        serializedName: "listening",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      protocols: {
        serializedName: "protocols",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Protocol",
            },
          },
        },
      },
      nodeVersion: {
        serializedName: "node_version",
        required: true,
        type: {
          name: "String",
        },
      },
      nodeRevision: {
        serializedName: "node_revision",
        required: true,
        type: {
          name: "String",
        },
      },
      peerCount: {
        serializedName: "peer_count",
        required: true,
        type: {
          name: "String",
        },
      },
      peerConnections: {
        serializedName: "peer_connections",
        type: {
          name: "Composite",
          className: "PeerConnections",
        },
      },
      pendingTransactionsCount: {
        serializedName: "pending_transactions_count",
        required: true,
        type: {
          name: "String",
        },
      },
      networkId: {
        serializedName: "network_id",
        required: true,
        type: {
          name: "String",
        },
      },
      peerPubkey: {
        serializedName: "peer_pubkey",
        required: true,
        type: {
          name: "String",
        },
      },
      topKeyBlockHash: {
        serializedName: "top_key_block_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      topBlockHeight: {
        serializedName: "top_block_height",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Protocol: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Protocol",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "String",
        },
      },
      effectiveAtHeight: {
        serializedName: "effective_at_height",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PeerConnections: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerConnections",
    modelProperties: {
      inbound: {
        serializedName: "inbound",
        required: true,
        type: {
          name: "String",
        },
      },
      outbound: {
        serializedName: "outbound",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Currency: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Currency",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      symbol: {
        serializedName: "symbol",
        required: true,
        type: {
          name: "String",
        },
      },
      subunit: {
        serializedName: "subunit",
        required: true,
        type: {
          name: "String",
        },
      },
      subunitsPerUnit: {
        serializedName: "subunits_per_unit",
        required: true,
        type: {
          name: "String",
        },
      },
      fiatConverstionUrl: {
        serializedName: "fiat_converstion_url",
        type: {
          name: "String",
        },
      },
      logo: {
        serializedName: "logo",
        type: {
          name: "Composite",
          className: "Image",
        },
      },
      primaryColour: {
        serializedName: "primary_colour",
        required: true,
        type: {
          name: "String",
        },
      },
      secondaryColour: {
        serializedName: "secondary_colour",
        required: true,
        type: {
          name: "String",
        },
      },
      networkName: {
        serializedName: "network_name",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Image: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Image",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      data: {
        serializedName: "data",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GasPricesItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GasPricesItem",
    modelProperties: {
      minGasPrice: {
        serializedName: "min_gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      utilization: {
        serializedName: "utilization",
        required: true,
        type: {
          name: "String",
        },
      },
      minutes: {
        serializedName: "minutes",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SpendTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SpendTx",
    modelProperties: {
      recipientId: {
        serializedName: "recipient_id",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      senderId: {
        serializedName: "sender_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OracleRegisterTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleRegisterTx",
    modelProperties: {
      queryFormat: {
        serializedName: "query_format",
        required: true,
        type: {
          name: "String",
        },
      },
      responseFormat: {
        serializedName: "response_format",
        required: true,
        type: {
          name: "String",
        },
      },
      queryFee: {
        serializedName: "query_fee",
        required: true,
        type: {
          name: "String",
        },
      },
      oracleTtl: {
        serializedName: "oracle_ttl",
        type: {
          name: "Composite",
          className: "Ttl",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OracleExtendTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleExtendTx",
    modelProperties: {
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      oracleTtl: {
        serializedName: "oracle_ttl",
        type: {
          name: "Composite",
          className: "RelativeTTL",
        },
      },
      oracleId: {
        serializedName: "oracle_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OracleQueryTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleQueryTx",
    modelProperties: {
      oracleId: {
        serializedName: "oracle_id",
        required: true,
        type: {
          name: "String",
        },
      },
      query: {
        serializedName: "query",
        required: true,
        type: {
          name: "String",
        },
      },
      queryFee: {
        serializedName: "query_fee",
        required: true,
        type: {
          name: "String",
        },
      },
      queryTtl: {
        serializedName: "query_ttl",
        type: {
          name: "Composite",
          className: "Ttl",
        },
      },
      responseTtl: {
        serializedName: "response_ttl",
        type: {
          name: "Composite",
          className: "RelativeTTL",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      senderId: {
        serializedName: "sender_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OracleRespondTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OracleRespondTx",
    modelProperties: {
      queryId: {
        serializedName: "query_id",
        required: true,
        type: {
          name: "String",
        },
      },
      response: {
        serializedName: "response",
        required: true,
        type: {
          name: "String",
        },
      },
      responseTtl: {
        serializedName: "response_ttl",
        type: {
          name: "Composite",
          className: "RelativeTTL",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      oracleId: {
        serializedName: "oracle_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NamePreclaimTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NamePreclaimTx",
    modelProperties: {
      commitmentId: {
        serializedName: "commitment_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameClaimTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameClaimTx",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      nameSalt: {
        serializedName: "name_salt",
        required: true,
        type: {
          name: "String",
        },
      },
      nameFee: {
        serializedName: "name_fee",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameUpdateTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameUpdateTx",
    modelProperties: {
      nameId: {
        serializedName: "name_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nameTtl: {
        serializedName: "name_ttl",
        required: true,
        type: {
          name: "String",
        },
      },
      pointers: {
        serializedName: "pointers",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NamePointer",
            },
          },
        },
      },
      clientTtl: {
        serializedName: "client_ttl",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameTransferTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameTransferTx",
    modelProperties: {
      nameId: {
        serializedName: "name_id",
        required: true,
        type: {
          name: "String",
        },
      },
      recipientId: {
        serializedName: "recipient_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameRevokeTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameRevokeTx",
    modelProperties: {
      nameId: {
        serializedName: "name_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "account_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CommitmentId: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CommitmentId",
    modelProperties: {
      commitmentId: {
        serializedName: "commitment_id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NameHash: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NameHash",
    modelProperties: {
      nameId: {
        serializedName: "name_id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelCreateTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelCreateTx",
    modelProperties: {
      initiatorId: {
        serializedName: "initiator_id",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorAmount: {
        serializedName: "initiator_amount",
        required: true,
        type: {
          name: "String",
        },
      },
      responderId: {
        serializedName: "responder_id",
        required: true,
        type: {
          name: "String",
        },
      },
      responderAmount: {
        serializedName: "responder_amount",
        required: true,
        type: {
          name: "String",
        },
      },
      channelReserve: {
        serializedName: "channel_reserve",
        required: true,
        type: {
          name: "String",
        },
      },
      lockPeriod: {
        serializedName: "lock_period",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      delegateIds: {
        serializedName: "delegate_ids",
        type: {
          name: "Composite",
          className: "Delegates",
        },
      },
    },
  },
};

export const ChannelDepositTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelDepositTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        required: true,
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelWithdrawTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelWithdrawTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      toId: {
        serializedName: "to_id",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        required: true,
        type: {
          name: "String",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelForceProgressTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelForceProgressTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        required: true,
        type: {
          name: "String",
        },
      },
      update: {
        serializedName: "update",
        type: {
          name: "Composite",
          className: "OffChainUpdate",
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      offchainTrees: {
        serializedName: "offchain_trees",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelCloseMutualTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelCloseMutualTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorAmountFinal: {
        serializedName: "initiator_amount_final",
        required: true,
        type: {
          name: "String",
        },
      },
      responderAmountFinal: {
        serializedName: "responder_amount_final",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelCloseSoloTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelCloseSoloTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      poi: {
        serializedName: "poi",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelSlashTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelSlashTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      poi: {
        serializedName: "poi",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelSettleTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelSettleTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorAmountFinal: {
        serializedName: "initiator_amount_final",
        required: true,
        type: {
          name: "String",
        },
      },
      responderAmountFinal: {
        serializedName: "responder_amount_final",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelSnapshotSoloTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelSnapshotSoloTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChannelSetDelegatesTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChannelSetDelegatesTx",
    modelProperties: {
      channelId: {
        serializedName: "channel_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fromId: {
        serializedName: "from_id",
        required: true,
        type: {
          name: "String",
        },
      },
      initiatorDelegateIds: {
        serializedName: "initiator_delegate_ids",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      responderDelegateIds: {
        serializedName: "responder_delegate_ids",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      stateHash: {
        serializedName: "state_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      round: {
        serializedName: "round",
        required: true,
        type: {
          name: "String",
        },
      },
      payload: {
        serializedName: "payload",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PubKey: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PubKey",
    modelProperties: {
      pubKey: {
        serializedName: "pub_key",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PeerDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerDetails",
    modelProperties: {
      host: {
        serializedName: "host",
        required: true,
        type: {
          name: "String",
        },
      },
      port: {
        serializedName: "port",
        required: true,
        type: {
          name: "String",
        },
      },
      firstSeen: {
        serializedName: "first_seen",
        required: true,
        type: {
          name: "String",
        },
      },
      lastSeen: {
        serializedName: "last_seen",
        required: true,
        type: {
          name: "String",
        },
      },
      genesisHash: {
        serializedName: "genesis_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      topHash: {
        serializedName: "top_hash",
        required: true,
        type: {
          name: "String",
        },
      },
      topDifficulty: {
        serializedName: "top_difficulty",
        required: true,
        type: {
          name: "String",
        },
      },
      networkId: {
        serializedName: "network_id",
        type: {
          name: "String",
        },
      },
      nodeVersion: {
        serializedName: "node_version",
        type: {
          name: "String",
        },
      },
      nodeRevision: {
        serializedName: "node_revision",
        type: {
          name: "String",
        },
      },
      nodeVendor: {
        serializedName: "node_vendor",
        type: {
          name: "String",
        },
      },
      nodeOs: {
        serializedName: "node_os",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Peers: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Peers",
    modelProperties: {
      peers: {
        serializedName: "peers",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      blocked: {
        serializedName: "blocked",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const PeerCount: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerCount",
    modelProperties: {
      connected: {
        serializedName: "connected",
        type: {
          name: "Composite",
          className: "PeerCountConnected",
        },
      },
      available: {
        serializedName: "available",
        type: {
          name: "Composite",
          className: "PeerCountAvailable",
        },
      },
      blocked: {
        serializedName: "blocked",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PeerCountConnected: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerCountConnected",
    modelProperties: {
      inbound: {
        serializedName: "inbound",
        type: {
          name: "String",
        },
      },
      outbound: {
        serializedName: "outbound",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PeerCountAvailable: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PeerCountAvailable",
    modelProperties: {
      verified: {
        serializedName: "verified",
        type: {
          name: "String",
        },
      },
      unverified: {
        serializedName: "unverified",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ContractCreateTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContractCreateTx",
    modelProperties: {
      ownerId: {
        serializedName: "owner_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        required: true,
        type: {
          name: "String",
        },
      },
      vmVersion: {
        serializedName: "vm_version",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      deposit: {
        serializedName: "deposit",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ContractCallTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContractCallTx",
    modelProperties: {
      callerId: {
        serializedName: "caller_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      contractId: {
        serializedName: "contract_id",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UnsignedTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UnsignedTx",
    modelProperties: {
      tx: {
        serializedName: "tx",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TokenSupply: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TokenSupply",
    modelProperties: {
      accounts: {
        serializedName: "accounts",
        type: {
          name: "String",
        },
      },
      contracts: {
        serializedName: "contracts",
        type: {
          name: "String",
        },
      },
      contractOracles: {
        serializedName: "contract_oracles",
        type: {
          name: "String",
        },
      },
      locked: {
        serializedName: "locked",
        type: {
          name: "String",
        },
      },
      oracles: {
        serializedName: "oracles",
        type: {
          name: "String",
        },
      },
      oracleQueries: {
        serializedName: "oracle_queries",
        type: {
          name: "String",
        },
      },
      pendingRewards: {
        serializedName: "pending_rewards",
        type: {
          name: "String",
        },
      },
      total: {
        serializedName: "total",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GAAttachTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GAAttachTx",
    modelProperties: {
      ownerId: {
        serializedName: "owner_id",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        required: true,
        type: {
          name: "String",
        },
      },
      vmVersion: {
        serializedName: "vm_version",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        required: true,
        type: {
          name: "String",
        },
      },
      authFun: {
        constraints: {
          Pattern: new RegExp("^(0x|0X)?[a-fA-F0-9]+$"),
        },
        serializedName: "auth_fun",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GAMetaTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GAMetaTx",
    modelProperties: {
      gaId: {
        serializedName: "ga_id",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      ttl: {
        serializedName: "ttl",
        type: {
          name: "String",
        },
      },
      authData: {
        serializedName: "auth_data",
        required: true,
        type: {
          name: "String",
        },
      },
      tx: {
        serializedName: "tx",
        type: {
          name: "Composite",
          className: "SignedTx",
        },
      },
    },
  },
};

export const PayingForTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PayingForTx",
    modelProperties: {
      payerId: {
        serializedName: "payer_id",
        required: true,
        type: {
          name: "String",
        },
      },
      fee: {
        serializedName: "fee",
        required: true,
        type: {
          name: "String",
        },
      },
      nonce: {
        serializedName: "nonce",
        type: {
          name: "String",
        },
      },
      tx: {
        serializedName: "tx",
        type: {
          name: "Composite",
          className: "SignedTx",
        },
      },
    },
  },
};

export const CheckTxInPoolResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CheckTxInPoolResponse",
    modelProperties: {
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OffChainTransfer: coreClient.CompositeMapper = {
  serializedName: "OffChainTransfer",
  type: {
    name: "Composite",
    className: "OffChainTransfer",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: OffChainUpdate.type.polymorphicDiscriminator,
    modelProperties: {
      ...OffChainUpdate.type.modelProperties,
      from: {
        serializedName: "from",
        required: true,
        type: {
          name: "String",
        },
      },
      to: {
        serializedName: "to",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OffChainWithdrawal: coreClient.CompositeMapper = {
  serializedName: "OffChainWithdrawal",
  type: {
    name: "Composite",
    className: "OffChainWithdrawal",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: OffChainUpdate.type.polymorphicDiscriminator,
    modelProperties: {
      ...OffChainUpdate.type.modelProperties,
      to: {
        serializedName: "to",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OffChainDeposit: coreClient.CompositeMapper = {
  serializedName: "OffChainDeposit",
  type: {
    name: "Composite",
    className: "OffChainDeposit",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: OffChainUpdate.type.polymorphicDiscriminator,
    modelProperties: {
      ...OffChainUpdate.type.modelProperties,
      from: {
        serializedName: "from",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OffChainNewContract: coreClient.CompositeMapper = {
  serializedName: "OffChainNewContract",
  type: {
    name: "Composite",
    className: "OffChainNewContract",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: OffChainUpdate.type.polymorphicDiscriminator,
    modelProperties: {
      ...OffChainUpdate.type.modelProperties,
      owner: {
        serializedName: "owner",
        required: true,
        type: {
          name: "String",
        },
      },
      vmVersion: {
        serializedName: "vm_version",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        type: {
          name: "Composite",
          className: "ByteCode",
        },
      },
      deposit: {
        serializedName: "deposit",
        required: true,
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OffChainCallContract: coreClient.CompositeMapper = {
  serializedName: "OffChainCallContract",
  type: {
    name: "Composite",
    className: "OffChainCallContract",
    uberParent: "OffChainUpdate",
    polymorphicDiscriminator: OffChainUpdate.type.polymorphicDiscriminator,
    modelProperties: {
      ...OffChainUpdate.type.modelProperties,
      caller: {
        serializedName: "caller",
        required: true,
        type: {
          name: "String",
        },
      },
      contract: {
        serializedName: "contract",
        required: true,
        type: {
          name: "String",
        },
      },
      abiVersion: {
        serializedName: "abi_version",
        required: true,
        type: {
          name: "String",
        },
      },
      amount: {
        serializedName: "amount",
        required: true,
        type: {
          name: "String",
        },
      },
      gas: {
        serializedName: "gas",
        required: true,
        type: {
          name: "String",
        },
      },
      gasPrice: {
        serializedName: "gas_price",
        required: true,
        type: {
          name: "String",
        },
      },
      callData: {
        serializedName: "call_data",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateContractUnsignedTx: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateContractUnsignedTx",
    modelProperties: {
      ...UnsignedTx.type.modelProperties,
      contractId: {
        serializedName: "contract_id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export let discriminators = {
  OffChainUpdate: OffChainUpdate,
  "OffChainUpdate.OffChainTransfer": OffChainTransfer,
  "OffChainUpdate.OffChainWithdrawal": OffChainWithdrawal,
  "OffChainUpdate.OffChainDeposit": OffChainDeposit,
  "OffChainUpdate.OffChainNewContract": OffChainNewContract,
  "OffChainUpdate.OffChainCallContract": OffChainCallContract,
};
