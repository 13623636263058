import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  DryRunInput as DryRunInputMapper,
  EncodedTx as EncodedTxMapper,
} from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const intAsString: OperationQueryParameter = {
  parameterPath: "intAsString",
  mapper: {
    defaultValue: false,
    serializedName: "int-as-string",
    type: {
      name: "Boolean",
    },
  },
};

export const hash: OperationURLParameter = {
  parameterPath: "hash",
  mapper: {
    serializedName: "hash",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const height: OperationURLParameter = {
  parameterPath: "height",
  mapper: {
    constraints: {
      InclusiveMaximum: 18446744073709552000,
      InclusiveMinimum: 0,
    },
    serializedName: "height",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const index: OperationURLParameter = {
  parameterPath: "index",
  mapper: {
    constraints: {
      InclusiveMinimum: 1,
    },
    serializedName: "index",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const pubkey: OperationURLParameter = {
  parameterPath: "pubkey",
  mapper: {
    serializedName: "pubkey",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const strategy: OperationQueryParameter = {
  parameterPath: ["options", "strategy"],
  mapper: {
    defaultValue: "max",
    serializedName: "strategy",
    type: {
      name: "Enum",
      allowedValues: ["max", "continuity"],
    },
  },
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: DryRunInputMapper,
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: EncodedTxMapper,
};

export const fromParam: OperationQueryParameter = {
  parameterPath: ["options", "from"],
  mapper: {
    serializedName: "from",
    type: {
      name: "String",
    },
  },
};

export const limit: OperationQueryParameter = {
  parameterPath: ["options", "limit"],
  mapper: {
    defaultValue: 20,
    constraints: {
      InclusiveMaximum: 1000,
      InclusiveMinimum: 1,
    },
    serializedName: "limit",
    type: {
      name: "Number",
    },
  },
};

export const typeParam: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    defaultValue: "all",
    serializedName: "type",
    type: {
      name: "Enum",
      allowedValues: ["open", "closed", "all"],
    },
  },
};

export const queryId: OperationURLParameter = {
  parameterPath: "queryId",
  mapper: {
    serializedName: "query-id",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const name: OperationURLParameter = {
  parameterPath: "name",
  mapper: {
    serializedName: "name",
    required: true,
    type: {
      name: "String",
    },
  },
};
